<template>
  <panel-alert title="设备解绑" isShowBottom width="434px" @onCloseClick="onCloseClick" @onConfirmClick="onConfirmClick">
    <template v-slot:content>
      <div class="unbind-breath-machine-alert">
        <p>是否要将设备解除绑定 ？</p>
      </div>
    </template>
  </panel-alert>
</template>

<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";

export default {
  components: {
    PanelAlert,
  },

  props: {
    patientDetailModel:Object
  },

  methods: {
    onCloseClick() {
      this.$emit("onCloseClick");
    },

    onConfirmClick() {
      this.unbindDevice();
    },

    async unbindDevice() {
      try {
        const params = {
          sn: this.patientDetailModel.deviceSN,
          uid: this.patientDetailModel.model.id
        };
        await this.$api.unbindUserAndDevice(params);
        this.$emit("onUnbindSucceedClick");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unbind-breath-machine-alert {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>